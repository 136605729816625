import React, { Component } from 'react'
import '../css/sidebar.css'

export default class Sidebar extends Component {
  state = {
    currentTime: new Date().toLocaleTimeString()
  };

  componentDidMount() {
    this.intervalID = setInterval(() => {
      this.setState({
        currentTime: new Date().toLocaleTimeString()
      });
    }, 1000);
  }

  componentWillUnmount() {
    clearInterval(this.intervalID);
  }

  render() {
    console.log('Sidebar component is mounted');
    return (
      <div>
        <div>
          <nav href="#navbar" className="js-colorlib-nav-toggle colorlib-nav-toggle" data-toggle="collapse" data-target="#navbar" aria-controls="navbar"><i /></nav>
          <aside id="colorlib-aside" className="border js-fullheight">
            <div className="text-center">
              <div className="author-img" style={{ backgroundImage: 'url(images/about.png)' }} />
              <h1 id="colorlib-logo"><a href="index.html">Alaa Badreddine</a></h1>
              <span className="position">Bioinformatician</span>
              <br></br>
              <span className="email"><i className="icon-mail"></i>abadreddine@pm.me</span>
            </div>
            <nav id="colorlib-main-menu" role="navigation" className="navbar">
              <div id="navbar" className="collapse">
                <ul>
                  <li className="active"><a href="#home" data-nav-section="home">Introduction</a></li>
                  <li><a href="#about" data-nav-section="about">About</a></li>
                  {/* <li><a href="#skills" data-nav-section="skills">Skills</a></li> */}
                  <li><a href="#education" data-nav-section="education">Education</a></li>
                  <li><a href="#experience" data-nav-section="experience">Experience</a></li>
                  {/* <li><a href="#contact" data-nav-section="contact">Contact</a></li> */}
                </ul>
              </div>
            </nav>
            <nav id="colorlib-main-menu">
              <ul className="social-row">
                <li><a href="https://www.facebook.com/BadreddineAlaa" target="_blank" rel="noopener noreferrer"><i className="icon-facebook2" /></a></li>
                <li><a href="https://twitter.com/Badreddine_Alaa" target="_blank" rel="noopener noreferrer"><i className="icon-twitter2" /></a></li>
                <li><a href="https://www.instagram.com/4laabadreddine/" target="_blank" rel="noopener noreferrer"><i className="icon-instagram" /></a></li>
              </ul>
              <ul className="social-row">
                <li><a href="https://www.linkedin.com/in/alaabadreddine/" target="_blank" rel="noopener noreferrer"><i className="icon-linkedin2" /></a></li>
                <li><a href="https://github.com/Z-Zen" target="_blank" rel="noopener noreferrer"><i className="icon-github"></i></a></li>
              </ul>
            </nav>
            <div className="colorlib-footer">
              <p>
                <small>
                  Made with ❤️<br />
                  Last updated: 14/12/2024 <br />
                  Current time: {this.state.currentTime}
                </small>
              </p>
            </div>
          </aside>
        </div>
      </div>
    )
  }
}
